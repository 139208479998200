import React from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../login.css';
const NewEmailTemplates = () => {
  const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        const name=e.target[0].value;
        const subject=e.target[1].value;
        const image=e.target[2].files[0];
        const body=e.target[3].value;
        
        try {
          const formData = new FormData();
        formData.append('name', name);
        formData.append('subject', subject);
        formData.append('body',body);
        formData.append('image', image);
          console.log(formData);
          const response = await axios.post('https://www.emailapi.elite-professionals.in/api/email-templates', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          });
          localStorage.setItem('token', response.data.token);
        } catch (error) {
          console.error(error);
        }
        navigate('/createtemplate');
      };

  return (
    <div className="outer-container">
    <div className='inner-container'>
    <h2 className="title">Create New Template</h2>
        <form onSubmit={handleSubmit}>
        <label htmlFor="name">TemplateName:</label>
        <input type="text" name="name" id="name" />
        <label htmlFor="subject">Subject:</label>
        <input type="text" name="subject" id="subject" />
        <label htmlFor="imageurl">Image URL:</label>
        <input type="file" accept="image/*" name="image" id="imageurl" />
        <label htmlFor="body">Body:</label>
        <textarea name="body" id="body" cols="57" rows="10"></textarea>
        <button type="submit">Save Template</button>
        </form>
        </div>
    </div>
  )
}

export default NewEmailTemplates
