import React from 'react'
import Login from './pages/Login';
import {BrowserRouter,Navigate,Route,Routes} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import EmailTemplates from './pages/EmailTemplates';
import NewEmailTemplates from './pages/NewEmailTemplates';
import SendMail from './pages/SendMail';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
     <Route path="/">
     <Route index element={<Dashboard/>}></Route>
     <Route path="login" element={<Login></Login>}></Route>
     <Route path="createtemplate" element={<EmailTemplates></EmailTemplates>}></Route>
     <Route path="newcreatetemplate" element={<NewEmailTemplates></NewEmailTemplates>}></Route>
     <Route path="newcreate" element={<SendMail></SendMail>}></Route>
     </Route>
    </Routes>
  </BrowserRouter>
  )
}

export default App;
