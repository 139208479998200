import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../emailtemplates.css'
import axios from 'axios';

function EmailTemplates() {
  const navigate = useNavigate();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [temp,setemp]=useState('');
  const [search,setsearch]=useState('');
  const [searchData,setSearchData]=useState([]);
  const [mailData,setMailData]=useState([]);
  const [response,setresponse]=useState([]);
  useEffect(() => {
    const fetchEmailTemplates = async () => {
      const response = await axios.get('https://www.emailapi.elite-professionals.in/api/email-templates', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setEmailTemplates(response.data);
    };
    fetchEmailTemplates();
  }, []);

  const handleDeleteTemplate = async (templateId) => {
    try {
      await axios.delete(`https://www.emailapi.elite-professionals.in/api/email-templates/${templateId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

    } catch (error) {
      console.error(error);
    }
    handleSearch();
  };

  const handleSearch = async() =>{
    try {
      const response = await axios.post('https://www.emailapi.elite-professionals.in/api/search', {search} );
      setSearchData(response.data);
    } catch (error) {
      console.error(error);
    }
  }
 
  const handleEdit = async(Id) => {
    try {
      console.log(Id);
      const response = await axios.get(`https://www.emailapi.elite-professionals.in/api/email-templates/${Id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      } );
      setMailData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const from_email=e.target[0].value;
    const to_email=e.target[1].value;
    const first_name=e.target[3].value;
    const last_name=e.target[4].value;

    try {
      const response = await axios.post('https://www.emailapi.elite-professionals.in/api/sendmail', {from_email,to_email,temp,first_name,last_name} );
      setresponse(response.data);
      alert('Email sent successfully!');
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className='outer-emailtemplates'>
      <div className="inner-emailtemplates">
        <div className="newtemplate">
        <a class="" href="/newcreatetemplate">Create New Template</a>
        </div>
      <div className="or">OR</div>
        <div className="search">
        <input type="text" name="search" id="" placeholder='Search for a template'value={search} onChange={(e) => setsearch(e.target.value)}/>
      <button type="submit" onClick={handleSearch}>Search</button>
        </div>
      {searchData && searchData.map((template) => (
        <div key={template.id} className='items'>
          <p>{template.name}</p>
          <div className="button">
          <button onClick={()=>setemp(template.name)} className="edit">Select</button>
          {/* <button onClick={() => handleDeleteTemplate(template.id)}className="delete">Delete</button> */}
          </div>
        </div>
      ))}
       {/* {mailData && mailData.map((data) => (
        <div key={data.id} className='items'> */}
           <form onSubmit={handleSubmit} className='form'>
          <label htmlFor="from_email">From:</label>
           <input type="email" id="from_email"/>
           <label htmlFor="to_email">To:</label>
           <input type="email" id="to_email" />
           <label htmlFor="template_name">Template Name:</label>
           <input type="text" id="template_name" value={temp}/>
           <label htmlFor="first_name">First Name:</label>
           <input type="text" id="last_name" />
           <label htmlFor="last_name">Last Name:</label>
           <input type="text" id="last_name" />
            <button type="submit">Send</button>
    </form>
        {/* </div>
      ))} */}
      </div>
    </div>
  );
}

export default EmailTemplates;
