import React from 'react'
import '../App.css'
const SendMail = () => {
  return (
    
    <div>
      <html>
              <head>
               <button><a href=" "></a></button>
              </head>
              <img src="" alt="" />
              <body>
                <div className="container">
                <div className='header'>
              <h2>This is a test email</h2>
              </div>
              <div className='body'>
              <p>Click to visit our website.</p>
              </div>
              <div className='copyright'>
                <p>copyright @2023 | All Rights Reserved Airepro</p>
              </div>
                </div>
              </body>
          </html>
    </div>
  )
}

export default SendMail

