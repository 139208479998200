import React from 'react';
import '../dashboard.css';
const Dashboard = () => {
  return (
    <div className='dashboard'>
      <div className="navbar">
        <div className="title">Dashboard</div>
        <div className="items">
        <span className='inner_items'><a class="" href="/login">Login</a></span>
        <span className='inner_items'><a class="" href="/createtemplate">EmailTemplate</a></span> 
        </div>
      </div>
    </div>
  )
}

export default Dashboard
